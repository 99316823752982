<template>
    <v-container class="fill-height px-12">
        <v-responsive class="align-start text-center fill-height pa-1">
            <router-view style="max-width: 1600px;" class="pb-3 flex-grow-1"></router-view>
        </v-responsive>
    </v-container>
</template>

<script>

export default {

}
</script>

<style lang="scss" scoped></style>